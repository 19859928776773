.project-item-container {
    display: flex;
    flex-direction: column;
    // width: calc(50% - 30px);
    width: 600px;
    margin: 0 15px 0 15px;
    justify-content: center;
    align-items: center;

    .project-name{
        font-size: 34px;
        margin-bottom: 20px;
        animation: headShake;
        animation-duration: 1.2s;
        animation-delay: 1s;
        
    }

    .project-image-container {
        overflow: hidden;
        margin-bottom: 18px;
        img{
            height: 250px;
            width: 450px;
            cursor: pointer;
            transition: .3s ease-in-out;
            -webkit-transition: .3s ease-in-out;
        }
        &:hover {
            box-shadow: 0px 0px 20px 12px #f6bc1f;

            img {
                transform: scale(1.3);
                -webkit-transform: scale(1.3);
            }
        }
    }

    .project-desc{
        font-size: 19px;
        margin-bottom: 15px;
        letter-spacing: 0.5px;
    }

    .project-link-container{
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        column-gap: 3%
    }
}

.project-item-container > * {
    margin-bottom: 10px;
}


@media only screen and (max-width: 600px) {
    .project-item-container {
        .project-name{
            font-size: 27px;
        }

        .project-image-container {
            img {
                width: 100%;
            }
            &:hover {
                box-shadow: 0px 0px 20px 7px #f6bc1f;
            }
        }

        .project-link-container{
            column-gap: 5%;
        }
    }
  }


