:root {
  --primaryBackground: #212222;
  --secondaryBackground: #2f3030;
  --bodyTextColor: #fff;
  --primaryColor: #fdbf00;
  --greyDark: #7d8792;
  --headerColor: #343838;
  --white: #fff;
  --black: #000;
  --sliver: #bac8d3;
  --text-shadow: 3px 3px 4px var(--black);
  --interest-field-shadow : 3px 3px 4px var(--black);
  --header-shadow: 0px 0px 35px 20px var(--black);
  --link-button-color : var(--black);
  --link-button-bg-color: var(--white);
  --link-button--hover-color: var(--black);
  --field-error-border: 2.5px solid red;
  --navlink-text-color: var(--white);
  --navLink-text-shadow: 0 0 1px var(--primaryColor), 3px 3px 0px var(--black);
  --navLink-text-hover-shadow: 0 0 1px var(--primaryColor), 3px 3px 0px var(--black);
  --navLink-text--active-shadow: 0 0 1px var(--primaryColor), 3px 3px 0px var(--black);
  --coding-image-opacity: 0.5;
  --circle-size: clamp(2.5rem, 4vw, 3rem);;
  --spacing: clamp(0.25rem, 2vw, 0.5rem);
  --stepper-color: var(--primaryColor);
  --animated-button-bg-color: #3bb9ff;
  --project-skill-bg-color: var(--primaryColor);
}

[data-theme="light"] {
  --primaryBackground: #fff;
  --secondaryBackground: #2f3030;
  --bodyTextColor: #000;
  --primaryColor: #f49e00;
  --greyDark: #7d8792;
  --headerColor: rgb(248, 248, 246);
  --white: #fff;
  --black: #000;
  --sliver: #bac8d3;
  --text-shadow: 3px 3px 4px var(--white);
  --interest-field-shadow : 2.5px 2px 2px var(--black);
  --header-shadow: 0px 0px 35px 5px #a39f9f;
  --link-button-color : var(--white);
  --link-button-bg-color: var(--black);
  --link-button--hover-color: var(--white);
  --field-error-border: 2px solid red;
  --navlink-text-color: var(--black);
  --navLink-text-shadow: none;
  --navLink-text-hover-shadow : none;
  --navLink-text--active-shadow: .8px .8px 0 #000;
  --coding-image-opacity: 0.4;
  --circle-size: clamp(2.5rem, 4vw, 3rem);
  --spacing: clamp(0.25rem, 2vw, 0.5rem);
  --stepper-color: var(--primaryColor);
  --animated-button-bg-color: #3bb9ff;
  --project-skill-bg-color: #666;
}

body {
  margin: 0;
  background-color: var(--primaryBackground);
  color: var(--bodyTextColor);
  font-family: 'Bubblegum Sans', serif;
  letter-spacing: 1px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body::-webkit-scrollbar {
  width: 10px;
  height: 18px;
}
body::-webkit-scrollbar-track {
  border-radius: 10px;
}
body::-webkit-scrollbar-thumb {
  background: rgb(165, 159, 159); 
  border-radius: 10px;
}
body::-webkit-scrollbar-thumb:hover {
  background: #6b6565; 
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
