
#nav-icon {
    width: 40px;
    height: 40px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
  }
  
  #nav-icon span {
    display: block;
    position: absolute;
    height: 5px;
    width: 45px;
    background: var(--primaryColor);
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }
  
  #nav-icon span:nth-child(1) {
    top: 1px;
  }
  
  #nav-icon span:nth-child(2) {
    top: 16px;
  }
  
  #nav-icon span:nth-child(3) {
    top: 31px;
  }
  
  #nav-icon.open span:nth-child(1) {
    top: 16px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  
  #nav-icon.open span:nth-child(2) {
    opacity: 0;
    left: -60px;
  }
  
  #nav-icon.open span:nth-child(3) {
    top: 16px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
  }
