.projects{
    animation: zoomIn;
    animation-duration: 1s;
}
.projects-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    row-gap: 40px;
    padding: 30px 80px 0 80px;
}

.project-header{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    div{
        &:hover {
            animation: rubberBand;
            animation-duration: 1s;
        }
    }
}


@media only screen and (max-width: 600px) {
    .projects-container {
        padding: 30px 0 0 0;
    }

    .project-header{
        font-size: 30px;
    }
  }