
.animated-btn {
    position: relative;
    display: inline-block;
    height: 50px;
    width: 200px;
    line-height: 50px;
    padding: 0;
    border-radius: 50px;
    background: #fdfdfd;
    border: 2px solid black;
    text-shadow: none;
    transition: .5s;
    &:hover {
        background-color: var(--animated-button-bg-color);
        .animated-btn-circle {
            left: 100%;
            margin-left: -44.4px;
            background-color: #fdfdfd;
            color: var(--animated-button-bg-color);
            border: 1px solid var(--black);
        }
        .animated-btn-title {
            left: 40px;
            opacity: 0;
        }
        .animated-btn-title-hover {
            opacity: 1;
            left: 35px;
        }
    }
    .animated-btn-circle {
        display: block;
        background-color: var(--animated-button-bg-color);
        color: #fff;
        position: absolute;
        float: left;
        margin: 5.9px 5px 5px 2px;
        line-height: 35px;
        height: 40px;
        width: 40px;
        top: -3px;
        left: 3px;
        transition: .5s;
        border-radius: 50%;
        > * {
            position: absolute;
            left: 7px;
            top: 8.5px;
            font-size: 22px;
        }
    }
    .animated-btn-title, .animated-btn-title-hover {
        position: absolute;
        left: 58px;
        text-align: center;
        margin: 0 auto;
        font-size: 19px;
        font-weight: bold;
        color: var(--black);
        transition: .5s;
    }
    .animated-btn-title-hover {
        opacity: 0;
        text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
        color: var(--white);
    }
}

@media only screen and (max-width: 480px) {
    .animated-btn {
        &:hover {
            background: #fdfdfd;
            .animated-btn-circle {
                left: 3px;
                margin: 5.9px 5px 5px 2px;
                background-color: var(--animated-button-bg-color);
                color: #fff;
                border: none;
            }
            .animated-btn-title {
                left: 58px;
                opacity: 1;
            }
            .animated-btn-title-hover {
                opacity: 0;
                left: 58px;
            }
        }
    }
}