.home-container {
    display: flex;
    min-height: 80vh;
}
.intro-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
    .text-container{
        flex: 1 1 auto;
        text-shadow: var(--text-shadow);
        letter-spacing: 1.4px;
        animation: bounceInLeft;
        animation-duration: 1s;
        h1 {
            font-size: 44px;
            .name-field {
                font-size: 72px;
                text-shadow: var(--text-shadow);
                animation: pulse;
                animation-duration: 1.2s;
                animation-delay: 1s;
            }
            .interest-field {
                font-size: 55px;
                color: var(--primaryColor);
                text-shadow: var(--interest-field-shadow);
            }
        }
        .download-cv-button-container{
            display: flex;
            margin-top: 35px;
            animation: bounceInUp;
            animation-duration: 1s;
        }
    }
    .coding-gif-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-bottom: 20px;
        flex: 1 1 auto;
        animation: bounceInDown;
        animation-duration: 1s;
        img{
            opacity: var(--coding-image-opacity);
            border-radius: 10px;
            height: 105px;
            width: 105px;
        }
    }
}

.home-image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    img{
        max-width: 100%;
        height: auto;
        width: 600px;
        animation: bounceInRight;
        animation-duration: 1s;
    }
}

@media only screen and (max-width: 950px) {
    .home-container {
        flex-direction: column;
    }
    .intro-container {
        width: 100%;
        align-items: center;
        .text-container{
            h1 {
                .hello-field {
                    text-align: center;
                }
                //font-size: 35px;
                // .name-field {
                //     text-align: center;
                //     font-size: 58px;
                // }
                // .interest-field {
                //     font-size: 38px;
                // }
            }
            .download-cv-button-container{
                justify-content: center;
            }
        }
        .coding-gif-container {
            img{
                height: 95px;
                width: 95px;
            }
        }
    }
    .home-image-container {
        width: 100%;
        margin-top: 20px;
        img{
            width: 550px;
        }
    }
}

  @media only screen and (max-width: 700px) {
    .intro-container {
        width: 100%;
        align-items: flex-start;
        margin-bottom: 35px;
        .text-container{
            letter-spacing: 1.2px;
            h1 {
                font-size: 34px;
                .hello-field {
                    text-align: left;
                }
                .name-field {
                    font-size: 50px;
                }
                .interest-field {
                    font-size: 37px;
                }
            }
            .download-cv-button-container{
                justify-content: flex-start;
                margin-top: 25px;
            }
        }
        .coding-gif-container {
            img{
                height: 80px;
                width: 80px;
            }
        }
    }
    .home-image-container {
        width: 100%;
        margin-top: 0px;
    }
  }

  @media only screen and (max-width: 420px) {
    .intro-container {
        .text-container{
            h1 {
                font-size: 34px;
                .name-field {
                    font-size: 50px;
                }
                .interest-field {
                    font-size: 37px;
                }
            }
        }
    }
}

@media only screen and (max-width: 400px) {
    .intro-container {
        .text-container{
            h1 {
                font-size: 30px;
                .name-field {
                    font-size: 45px;
                }
                .interest-field {
                    font-size: 33px;
                }
            }
        }
    }
}


@media only screen and (max-width: 300px) {
    .intro-container {
        .text-container{
            h1 {
                font-size: 25px;
                .name-field {
                    font-size: 40px;
                }
                .interest-field {
                    font-size: 28px;
                }
            }
        }
    }
}