.stepper-item {
    position: relative;
    display: flex;
    gap: 1rem;
    padding-bottom: 3rem;
    
    &:before {
      content: "";
      flex: 0 0 var(--circle-size);
      height: var(--circle-size);
      border-radius: 50%;
      background-color: var(--stepper-color);
    }
  
    &:not(:last-child) {
      &:after {
        content: "";
        position: absolute;
        left: 0;
        top: calc(var(--circle-size) + var(--spacing));
        bottom: var(--spacing);
        z-index: -1;
        transform: translateX(calc(var(--circle-size) / 2));
        width: 4px;
        border-radius: 50%;
        background-color: var(--stepper-color);
      }
    }
    .stepper-content {
        padding-top: 10px;
        .stepper-title {
            letter-spacing: 1px;
            font-size: clamp(1.2rem, 4vw, 1.35rem);
            margin-bottom: clamp(0.85rem, 2vmax, 1rem);
          }
          
          .stepper-desc {
            color: grey;
            line-height: 1.3;
            font-size: clamp(1.2rem, 2vmax, 1.2rem);
          }
      }    
  }

