.about-header{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    animation: zoomIn;
    animation-duration: 1s;
    div{
        &:hover {
            animation: rubberBand;
            animation-duration: 1s;
        }
    }
}

.about-container {
    display: flex;
    padding: 30px 0 0 0;
    overflow: hidden;
    .about-desc {
        width: 55%;
        padding: 0 10px 0 10px;
        font-size: 22px;
        animation: fadeInLeft;
        animation-duration: 1s;
        img {
            height: 250px;
            width: 250px;
            border-radius: 20px;
            float: left;
            margin-right: 15px;
            margin-bottom: 7px;
            border: 2px solid var(--white);
        }
        .contact-link{
            a {
                text-decoration: none;
                color: var(--primaryColor);
                &:hover{
                    text-decoration: underline;
                }
            }
        }
    }
    .work-experience {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 45%;
        padding: 0 20px 0 20px;
        animation: flipInY;
        animation-duration: 1.2s;
    }
}

@media only screen and (max-width: 825px) {
    .about-container {
        .about-desc {
            img {
                height: 230px;
                width: 230px;
            }
        }
    }
}

@media only screen and (max-width: 700px) {
    .about-container {
        flex-direction: column;
        .about-desc {
            width: 100%;
            margin-bottom: 20px;
            padding: 0;
        }
        .work-experience{
            width: 100%;
            padding: 0;
        }
    }
}

@media only screen and (max-width: 500px) {
    .about-container {
        .about-desc {
            img {
                height: 190px;
                width: 190px;
            }
        }
    }
}

@media only screen and (max-width: 400px) {
    .about-container {
        .about-desc {
            img {
                height: 170px;
                width: 170px;
            }
        }
    }
}