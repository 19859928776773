.contact-container {
    margin: auto;
    max-width: 90%;

    .contact-header{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 40px;
        margin-bottom: 30px;
        div{
            &:hover{
                animation: rubberBand;
                animation-duration: 1s;
            }
        }
    }

    .contact-content-container{
        display: flex;
        justify-content: space-between;
        .contact-form-container {
            width: 65%;
            .contact-form-name-email-container {
                display: flex;
                > div{
                    width: 100%;
                    &:first-child {
                        margin-right: 15px;
                    }
                }
            }
            input {
                width: 100%;
                border: 2px solid black;
                background: var(--white);
                height: 50px;
                font-family: 'Bubblegum Sans', serif;
                font-size: 20px;
                padding: 20px;
                margin: 10px 0;
                border-radius: 5px;
                outline: none;
                letter-spacing: 0.8px;
                animation: fadeInLeft 1s;
            }
            textarea {
                width: 100%;
                border: 2px solid black;
                background: var(--white);
                font-size: 20px;
                padding: 20px;
                border-radius: 5px;
                outline: none;
                height: 256px;
                font-family: 'Bubblegum Sans', serif;
                margin: 10px 0;
                letter-spacing: 0.8px;
                animation: fadeInLeft 1s;
            }
            button{
                border: none;
                border-radius: 5px;
                margin: 10px 0;
                outline: none;
                padding: 5px;
                width: 100%;
                cursor: pointer;
                background-color: var(--primaryColor);
                font-size: 21px;
                font-weight: bold;
                line-height: 48px;
                font-family: 'Bubblegum Sans', serif;
                letter-spacing: 0.8px;
                animation: fadeInLeft 1s;
                &:disabled{
                    cursor: default;
                }
            }
        }
        .contact-details-container {
            width: 35%;
            padding: 8px 10px 0 40px;
            letter-spacing: 0.2px;
            font-size: 24px;
            animation: backInDown 1.2s;
            .contact-me-icons{
                display: flex;
                flex-wrap: wrap;
                column-gap: 20px;
                row-gap: 10px;
                margin-top: 12px;
                animation: headShake 1.5s 1.2s;
                img{
                    height: 40px;
                    width: 40px;
                }
            }
        }
    }
}

.field-error {
    // box-shadow: inset 0 0 0 2px red;
    border: var(--field-error-border) !important;
}

@media only screen and (max-width: 1024px) {
    .contact-container{
        .contact-header{
            font-size: 33px;
            margin-bottom: 10px;
        }
        .contact-content-container {
            .contact-form-container {
                input{
                    height: 40px;
                    font-size: 16px;
                }
                textarea {
                    height: 180px;
                    font-size: 16px;
                }
            }

            .contact-details-container {
                font-size: 22px;
            }
        }
    }
}

@media only screen and (max-width: 820px) {
    .contact-container{
        max-width: 90%;
        .contact-content-container{
            flex-direction: column;
            .contact-form-container {
                width: 100%;
                .contact-form-name-email-container {
                    flex-direction: column;
                }
                input{
                    height: 50px;
                    font-size: 20px;
                    letter-spacing: 0.5px;
                }
                textarea {
                    height: 256px;
                    font-size: 20px;
                    letter-spacing: 0.5px;
                }
            }
            .contact-details-container {
                width: 100%;
                font-size: 22px;
                padding: 8px 0 0 0;
                .contact-me-icons{
                    img{
                        height: 35px;
                        width: 35px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 380px) {
    .contact-container{
        .contact-content-container{
            .contact-form-container {
                input{
                    height: 30px;
                    letter-spacing: 0.2px;
                }
                textarea {
                    height: 200px;
                    letter-spacing: 0.2px;
                }
            }
        }
    }
}

@media only screen and (max-width: 290px) {
    .contact-container{
        .contact-header{
            font-size: 25px;
            margin-bottom: 10px;
        }

        .contact-content-container{
            letter-spacing: 0.5px;
            .contact-form-container {
                input{
                    font-size: 14px;
                    padding: 10px;
                }
                textarea {
                    font-size: 15px;
                    padding: 10px;
                }
                button {
                    line-height: 35px;
                }
            }
            .contact-details-container {
                font-size: 18px;
            }
        }
    }
}