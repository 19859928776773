.header-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--headerColor);
    height: 10vh;
    line-height: 10vh;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 999;
    padding: 0 30px 0 30px;
    box-shadow: var(--header-shadow);
    .logo-container {
        display: flex;
        align-items: center;
        justify-content: center;
        a{
            text-decoration: none;
            color: var(--navlink-text-color);
            text-shadow: var(--navLink-text-shadow);
        }
        .logo-image-container {
            width: 48px;
            height: 48px;
            border-radius: 50px;
            overflow: hidden;
            margin-right: 10px;
            background: var(--primaryColor);
            border: 1px solid black;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                display: block;
            }
        }
        .logo-name{
            font-size: 22px;
        }  
    }
    .navlink-container{
        display: flex;
        justify-content: center;
        align-items: center;
        .navLinks{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            font-weight: 500;
            font-size: 21px;
            gap: 25px;
            a {
                color: var(--navlink-text-color);
                text-decoration: none;
                transition: 0.4s;
                text-shadow: var(--navLink-text-shadow);
            }
            
            a:hover {
                text-shadow: var(--navLink-text-hover-shadow);
                transform: scale(1.2); 
            }
    
            a.active{
                color: var(--primaryColor);
                text-shadow: var(--navLink-text--active-shadow);
                transform: scale(1.1);
                :hover{
                    transform: none;
                }
            }
            
            .header-social-icons{
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 9px;
                a{
                    padding-top: 8px;
                    > * {
                        font-size: 25px;
                        color: var(--primaryColor);
                    }
                }
            }
            .dark-mode-toggle-switch{
                padding-top: 8px;
            }
        }
    }
    .dark-mode-toggle-switch-responsive {
        display: none;
        padding-top: 8px;
    }
    .hamburger-icon {
        display: none;
        padding-top: 2px;
    }
}

.drawer{
    position: fixed;
    top: 10vh;
    left: 0;
    background-color: var(--headerColor);
    z-index: 999;
    width: 100%;
    display: block;
    height: 0px;
    opacity: 0;
    overflow: hidden;
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 300ms, opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
   }

  .drawer.drawer-open {
    opacity: 1;
    height: 320px;
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  .drawer-navlink-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    font-weight: 500;
    font-size: 23px;
    gap: 25px;
    padding: 10px;
    a {
        color: var(--navlink-text-color);
        text-decoration: none;
        transition: 0.4s;
        text-shadow: var(--navLink-text-shadow);
    }
    a.active{
        color: var(--primaryColor);
        text-shadow: var(--navLink-text--active-shadow);
    }

    .drawer-header-social-icons {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 18px;
        > * {
            font-size: 27px;
            color: var(--primaryColor);
        }
    }
  }

@media only screen and (max-width: 870px) {
    .logo-container{
        .logo-name{
            display: none;
        }
    }
}

@media only screen and (max-width: 750px) {
    .header-container{
        .navlink-container{
            display: none;
            .navLinks{
                display: none;
            }
            .dark-mode-toggle-switch{
                display: none;
            }
        }
        .dark-mode-toggle-switch-responsive {
            display: inline-block;
        }
        .hamburger-icon {
            display: inline-block;
        }
    }
}

@media only screen and (min-width: 750px) {
    .drawer.drawer-open{
        height: 0px;
        opacity: 0;
        overflow: hidden;
    }
}
