.project-skills-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 15px;
    .project-skill {
        padding: 10px 20px;
        font-size: 16px;
        background: rgba(153,153,153,.2);
        border-radius: 5px;
        font-weight: 600;
        color: var(--project-skill-bg-color);
        &:hover{
            animation: headShake 1s;
        }
    }
}
