.skills-header{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    animation: zoomIn;
    animation-duration: 1s;
    div{
        &:hover {
            animation: rubberBand;
            animation-duration: 1s;
        }
    }
}

.skills-container {
    display: flex;
    padding: 30px 0 0 0;
    overflow: hidden;
    .skills-desc {
        width: 40%;
        padding: 0 10px 0 10px;
        font-size: 22px;
        animation: fadeInLeft;
        animation-duration: 1s;
    }
    .skills-info {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 60%;
        padding: 0 20px 0 20px;
        animation: fadeInRight;
        animation-duration: 1s;
    }
}

@media only screen and (max-width: 900px) {
    .skills-container {
        flex-direction: column;
        .skills-desc {
            width: 100%;
            margin-bottom: 20px;
            padding: 0;
        }
        .skills-info {
            width: 100%;
            padding: 0;
        }
    }
}

@media only screen and (max-width: 480px) {
    .skills-desc {
        order: 2;
    }

    .skills-container {
        .skills-info {
            order: 1;
            margin-bottom: 30px;
        }
    }
}