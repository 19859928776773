.skill-category-container {
    display: flex;
    flex-direction: column;

    .skill-category-header{
        font-size: 30px;
        color: var(--primaryColor);
        margin-bottom: 20px;
    }

    .skill-details-container{
        display: flex;
        gap: 30px;
        flex-wrap: wrap;
        .skill-category-item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            // row-gap: 10px;
            &:hover {
                animation: swing;
                animation-duration: 0.5s;
            }
            img{
                width: 80px;
                height: 80px;
                margin-bottom: 10px;
                animation: swing;
                animation-duration: 1.2s;
                animation-delay: 1s;
            }
            .skill-category-name{
                font-size: 20px;
            }
        }
    }
}

@media only screen and (max-width: 480px) {
    .skill-category-container {
        .skill-details-container {
            gap: 20px;
            justify-content: space-around;
            .skill-category-item {
                &:hover {
                    animation: none
                }
            }
        }
    }
}