.link-button{
    text-decoration: none;
    color: var(--link-button-color);
    background-color: var(--link-button-bg-color);
    border-radius: 50vmax;
    font-size: 1.2rem;
    font-weight: bold;
    padding: 0.4rem 0.8rem 0.4rem 0.8rem;

    &:hover {
        color: var(--link-button--hover-color);
        background-color: var(--primaryColor);
    }
}

@media only screen and (max-width: 600px) {
    .link-button{ 
        font-size: 1rem;

        &:hover {
            color: var(--link-button-color);
            background-color: var(--link-button-bg-color);
        }
    }
}
